import { usePocket } from "../../PocketContext";
import dayjs from "dayjs";

export const usePocketActions = () => {
  const { pb } = usePocket();

  const getEmployees = async () => {
    const response = await pb.collection("employees").getFullList();

    return response.map((item) => ({
      id: item.id,
      name: item.name,
    }));
  };

  const getAppointments = async () => {
    const response = await pb.collection("appointments").getFullList({
      // filter: `date >= "${dayjs().format()}"`,
      expand: "client,services",
      sort: "-created",
    });

    return response.map((item) => ({
      id: item.id,
      services: item.expand.services.map((service) => ({
        id: service.id,
        name: service.name,
        steps: service.steps,
      })),
      date: item.date,
      employee: item.employee,
      client: {
        id: item.expand.client.id,
        name: item.expand.client.name,
        phone: item.expand.client.phone,
      },
    }));
  };

  const getAppointmentsByEmployee = (employee) => async () => {
    if (!employee) return [];

    const response = await pb.collection("appointments").getFullList({
      filter: `employee = "${employee}"`,
      expand: "client,services",
      sort: "-created",
    });

    return response.map((item) => ({
      id: item.id,
      services: item.expand.services.map((service) => ({
        id: service.id,
        name: service.name,
        steps: service.steps,
      })),
      date: item.date,
      employee: item.employee,
      client: {
        id: item.expand.client.id,
        name: item.expand.client.name,
        phone: item.expand.client.phone,
      },
    }));
  };

  const getServices = async () => {
    const response = await pb.collection("services").getFullList();

    return response.map((item) => ({
      id: item.id,
      name: item.name,
      steps: item.steps,
    }));
  };

  const getClients = async () => {
    const response = await pb.collection("clients").getFullList();

    return response.map((item) => ({
      id: item.id,
      name: item.name,
      phone: item.phone,
    }));
  };

  const addAppointment = async (appoinment) => {
    await pb.collection("appointments").create({
      client: appoinment.client.id,
      services: appoinment.services.map((service) => service.id),
      employee: appoinment.employee.id,
      date: dayjs(appoinment.date).format(),
    });
  };

  const addClient = async (client) => {
    await pb.collection("clients").create({
      name: client,
      phone: "",
    });
  };

  return {
    getClients,
    getServices,
    getEmployees,
    addClient,
    addAppointment,
    getAppointments,
    getAppointmentsByEmployee,
  };
};
