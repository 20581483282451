import { Schedule } from "../../../components/schedule";
import { usePocketActions } from "../../../hooks/use-pocket-actions";
import useSWR from "swr";
import { useMemo } from "react";
import { appointmentsParser } from "../../../utils/data";

export const Calendar = () => {
  const { getEmployees, getAppointments } = usePocketActions();

  // TODO: juntar en uno (?)
  const { data: employees, isLoading: isLoadingEmployees } = useSWR(
    "/getEmployees",
    getEmployees,
    {
      fallbackData: [],
    },
  );

  const { data: appointments, isLoading: isLoadingAppointments } = useSWR(
    `/getAppointments`,
    getAppointments,
    {
      fallbackData: [],
    },
  );

  const isLoading = useMemo(
    () => isLoadingEmployees || isLoadingAppointments,
    [isLoadingEmployees, isLoadingAppointments],
  );

  const resources = useMemo(
    () => employees.map((e) => ({ id: e.id, title: e.name })),
    [employees],
  );

  const events = useMemo(
    () => appointmentsParser(appointments),
    [appointments],
  );

  return (
    <div className={"flex flex-col gap-8"}>
      {isLoading && <p>Loading...</p>}
      <Schedule resources={resources} events={events} />
    </div>
  );
};
