import { Auth } from "./pages/auth";

import { ProtectedRoute } from "./components/protected-route";
import { Root } from "./layout";
import { createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./pages/error";
import { Dashboard } from "./pages/dashboard";
import { Calendar } from "./pages/dashboard/calendar";
import { NotFoundPage } from "./pages/404";
import { AddEvent } from "./pages/dashboard/add";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: "/",
        element: <Auth />,
      },
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: "/dashboard",
            element: <Dashboard />,
            children: [
              {
                index: true,
                element: <Calendar />,
              },
            ],
          },
          {
            path: "/add",
            element: <Dashboard />,
            children: [
              {
                index: true,
                element: <AddEvent />,
              },
            ],
          },
        ],
      },
      { path: "*", element: <NotFoundPage /> },
    ],
  },
]);
