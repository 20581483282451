import { Outlet } from "react-router-dom";

export const Dashboard = () => {
  return (
    <div className={"flex w-full h-full gap-8 p-4"}>
      <div className={"w-full"}>
        <Outlet />
      </div>
    </div>
  );
};
