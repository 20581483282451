import dayjs from "dayjs";

export const appointmentsParser = (appointments) =>
  appointments.reduce((acc, curr) => {
    const eventData = {
      id: curr.id,
      clientName: curr.client.name,
      resourceId: curr.employee,
    };

    let startDate = curr.date;
    let accDuration = 0;

    curr.services.forEach((service) => {
      const actions = service.steps
        .map((step) => {
          const start = dayjs(startDate).add(accDuration, "m");
          accDuration += step.duration;

          if (step.hide) return null;

          return {
            ...eventData,
            service: step.action,
            start: start.format(),
            end: start.add(step.duration, "m").format(),
          };
        })
        .filter(Boolean);

      acc.push(...actions);
    });

    return acc;
  }, []);
