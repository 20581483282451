import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";

import { router } from "./Router";
import { PocketProvider } from "./PocketContext";

export const App = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== "development") {
      const script = document.createElement("script");
      script.src = process.env.REACT_APP_TRACKER_URL;
      script.defer = true;
      script.dataset.websiteId = process.env.REACT_APP_TRACKER_ID;
      document.head.appendChild(script);
    }
  }, []);
  return (
    <React.StrictMode>
      <PocketProvider>
        <RouterProvider router={router} />
      </PocketProvider>
    </React.StrictMode>
  );
};
