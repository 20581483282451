import { useCallback, useMemo, useRef, useState } from "react";
import Select from "react-select";
import { usePocketActions } from "../../../hooks/use-pocket-actions";
import useSWR, { mutate } from "swr";
import { appointmentsParser } from "../../../utils/data";
import { Schedule } from "../../../components/schedule";
import dayjs from "dayjs";

export const AddEvent = () => {
  const {
    getServices,
    getClients,
    getEmployees,
    getAppointmentsByEmployee,
    addAppointment,
  } = usePocketActions();
  const [data, setData] = useState({});

  const { data: services, isLoading: isLoadingServices } = useSWR(
    "/getServices",
    getServices,
    {
      fallbackData: [],
    },
  );

  const { data: clients, isLoading: isLoadingClients } = useSWR(
    "/getClients",
    getClients,
    {
      fallbackData: [],
    },
  );

  const { data: employees, isLoading: isLoadingEmployees } = useSWR(
    "/getEmployees",
    getEmployees,
    {
      fallbackData: [],
    },
  );

  const { data: appointments, isLoading: isLoadingAppointments } = useSWR(
    `/getAppointmentsByEmployee/${data.employee?.id}`,
    getAppointmentsByEmployee(data?.employee?.id),
    {
      fallbackData: [],
    },
  );

  const calendarRef = useRef(null);

  const onChange = (label) => (e) => {
    const value = e?.target?.value
      ? e?.target?.value
      : Array.isArray(e)
        ? e.map((c) => ({ id: c.value, title: c.label }))
        : { id: e.value, title: e.label };

    if (label === "date" && calendarRef.current) {
      calendarRef.current.getApi().gotoDate(dayjs(value).format("YYYY-MM-DD"));
    }

    setData((d) => ({ ...d, [label]: value }));
  };

  const firstStep = useMemo(() => !!data?.date && !!data?.employee, [data]);

  const events = useMemo(
    () => appointmentsParser(appointments),
    [appointments],
  );

  const renderSchedule = useCallback(() => {
    if (isLoadingAppointments) {
      return <p>cargando...</p>;
    }

    return (
      <Schedule
        calendarRef={calendarRef}
        events={events}
        controls={false}
        resources={[data.employee]}
        initialDate={dayjs(data.date).format("YYYY-MM-DD")}
      />
    );
  }, [isLoadingAppointments, events, data.employee, data.date]);

  const onSave = useCallback(async () => {
    await addAppointment(data);
    await mutate(`/getAppointmentsByEmployee/${data.employee?.id}`);
  }, [data, addAppointment]);

  // TODO: si no existe el cliente -> crear

  return (
    <div className={"flex flex-row w-full gap-8"}>
      <div className={"w-1/3 p-4"}>
        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
          Añadir nueva cita
        </h2>
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Fecha
              </label>
              <div className="mt-2">
                <input
                  onChange={onChange("date")}
                  id="date"
                  name="date"
                  type="datetime-local"
                  required
                  className="block w-full p-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="employee"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Empleado
                </label>
              </div>
              <div className="mt-2">
                <Select
                  onChange={onChange("employee")}
                  placeholder={"Seleccionar empleado"}
                  options={employees.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  isLoading={isLoadingEmployees}
                />
              </div>
            </div>

            <div className={!firstStep ? "opacity-50 cursor-not-allowed" : ""}>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="client"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Cliente
                </label>
              </div>
              <div className="mt-2">
                <Select
                  onChange={onChange("client")}
                  isDisabled={!firstStep}
                  placeholder={"Seleccionar cliente"}
                  options={clients.map((c) => ({ value: c.id, label: c.name }))}
                  isLoading={isLoadingClients}
                />
              </div>
            </div>

            <div className={!firstStep ? "opacity-50 cursor-not-allowed" : ""}>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="services"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Servicios
                </label>
              </div>
              <div className="mt-2">
                <Select
                  onChange={onChange("services")}
                  isDisabled={!firstStep}
                  placeholder={"Seleccionar servicios"}
                  options={services.map((c) => ({
                    value: c.id,
                    label: c.name,
                  }))}
                  isLoading={isLoadingServices}
                  isMulti
                />
              </div>
            </div>

            <div>
              <button
                onClick={onSave}
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className={"w-3/4"}>
        {firstStep ? <div>{renderSchedule()}</div> : null}
      </div>
    </div>
  );
};
