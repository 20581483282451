import { useRouteError } from "react-router-dom";

export const ErrorPage = () => {
  const error = useRouteError();
  console.error(error);

  return (
    <div
      id="error-page"
      className={"flex flex-col min-h-full justify-center items-center gap-4"}
    >
      <h1 className={"text-3xl"}>Oops!</h1>
      <p className={"text-xl"}>Sorry, an unexpected error has occurred.</p>
      <div className="mockup-code">
        <pre data-prefix="$" className="text-warning">
          <code>{error.statusText || error.message}</code>
        </pre>
      </div>
    </div>
  );
};
