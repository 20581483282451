import React from "react";
import FullCalendar from "@fullcalendar/react";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import esLocale from "@fullcalendar/core/locales/es";
import dayjs from "dayjs";
import "./index.css";

export const Schedule = ({
  calendarRef = null,
  resources,
  events,
  initialDate = null,
  controls = true,
}) => {
  const handleEventClick = (clickInfo) => {
    console.log(clickInfo.event);
  };

  const renderEventContent = (eventInfo) => {
    const { clientName, service } = eventInfo.event.extendedProps;

    const duration = dayjs(eventInfo.event.end).diff(
      dayjs(eventInfo.event.start),
      "minute",
    );

    if (duration <= 15) {
      return (
        <div className={"px-2 flex-row justify-between items-center"}>
          <span
            className={
              "text-[12px] tracking-tighter font-semibold text-ellipsis line-clamp-1"
            }
          >
            {`${clientName}, ${eventInfo.timeText.split("-")[0]}`}
          </span>
          <span
            className={
              "text-[12px] tracking-tighter font-semibold text-ellipsis line-clamp-1"
            }
          >
            {service}
          </span>
        </div>
      );
    }

    if (duration <= 30) {
      return (
        <div className={"px-2 flex flex-col"}>
          <div className={"flex flex-row justify-between"}>
            <span
              className={
                "text-[12px] tracking-tighter font-bold text-ellipsis line-clamp-1"
              }
            >
              {clientName}
            </span>
            <span
              className={
                "text-[12px] tracking-tighter font-bold text-ellipsis line-clamp-1"
              }
            >
              {service}
            </span>
          </div>
          <span className={"text-[12px] tracking-tighter"}>
            {eventInfo.timeText}
          </span>
        </div>
      );
    }

    return (
      <div className={"px-2 py-1 flex flex-col"}>
        <span
          className={
            "text-[12px] tracking-tighter font-bold text-ellipsis line-clamp-1"
          }
        >
          {clientName}
        </span>
        <span className={"text-[12px] tracking-tighter"}>
          {eventInfo.timeText}
        </span>
        <span className={"text-[12px] font-bold tracking-tighter"}>
          {service}
        </span>
      </div>
    );
  };

  // TODO: businessHours-per-resource por si queremos meter el horarion fijo de cada empleado
  return (
    <div>
      <FullCalendar
        ref={calendarRef}
        locale={esLocale}
        firstDay={1}
        initialDate={initialDate}
        plugins={[resourceTimeGridPlugin]}
        initialView="resourceTimeGridDay"
        nowIndicator={true}
        height="auto"
        resources={resources}
        events={events}
        eventClick={handleEventClick}
        eventContent={renderEventContent}
        slotDuration={"00:15:00"}
        scrollTime={"09:00:00"}
        slotMinTime={"09:00:00"}
        slotMaxTime={"19:00:00"}
        businessHours={[
          {
            daysOfWeek: [2, 3, 4, 5], // Martes - Viernes
            startTime: "09:30",
            endTime: "18:30",
          },
          {
            daysOfWeek: [6], // Sabado
            startTime: "09:30",
            endTime: "14:30",
          },
        ]}
        headerToolbar={{
          left: controls ? "prev,next today" : "",
          center: "title",
          right: "",
        }}
        allDaySlot={false}
        eventClassNames={(arg) => {
          // console.log(arg);
          // if (arg.event.extendedProps.resourceId === "1") {
          //   return ["event-juan"];
          // }
          // if (arg.event.extendedProps.resourceId === "2") {
          //   return ["event-maria"];
          // }
          // return ["event-default"];
        }}
        schedulerLicenseKey={"CC-Attribution-NonCommercial-NoDerivatives"}
      />
    </div>
  );
};
