import { NavLink } from "react-router-dom";

export const MenuLink = ({ name, Icon, to, end = false, onPress }) => {
  const style =
    "flex flex-row items-center gap-1 transition-colors hover:scale-110";

  return (
    <NavLink
      onClick={onPress}
      to={to}
      end={end}
      className={({ isActive, isPending }) =>
        isActive ? `${style} text-blue-700` : style
      }
    >
      <Icon size={20} />
      {name}
    </NavLink>
  );
};
