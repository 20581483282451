import { Header } from "./header";
import { MainLayout } from "./main";
import { Outlet } from "react-router-dom";
import { Footer } from "./footer";
import React from "react";

export const Root = () => {
  return (
    <>
      <Header />
      <MainLayout>
        <Outlet />
      </MainLayout>
      <Footer />
    </>
  );
};
