import { usePocket } from "../../PocketContext";
import { Link } from "react-router-dom";
import { LuCalendarDays, LuCalendarPlus, LuLogOut } from "react-icons/lu";
import { MenuLink } from "../../components/menu-link";

export const Header = () => {
  const { user, logout } = usePocket();

  return user ? (
    <header
      className={
        "flex border-b-2 border-gray-200 items-center justify-between p-4"
      }
    >
      <Link to={"/"}>
        <img
          width={50}
          height={50}
          className={"pointer-events-none h-14 w-auto brightness-0 filter"}
          src="/assets/logo.png"
          alt="Logo"
        />
      </Link>

      <div className={"flex flex-row gap-8 items-center"}>
        <MenuLink name={"Agenda"} Icon={LuCalendarDays} to={"/dashboard"} />
        <MenuLink name={"Nueva cita"} Icon={LuCalendarPlus} to={"/add"} />
        <button
          className={
            "bg-gray-100 p-2 rounded-full transition-colors hover:bg-gray-200"
          }
          onClick={logout}
        >
          <LuLogOut size={24} />
        </button>
      </div>
    </header>
  ) : null;
};
